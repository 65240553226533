<template>
  <v-card class="elevation-1">
    <v-card-title>
      Deductions
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          {{iconList.edit}}
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          {{iconList.delete}}
        </v-icon>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiDeleteOutline, mdiPencilOutline, mdiPlusOutline } from '@mdi/js'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {
  props: ['uniqueID'],
  setup(props, { emit }) {
    const search = ref('')
    const headers = ref([
      { text: 'Emp. No', align: 'start', value: 'employee_number' },
      { text: 'Emp. Name', value: 'employee_name' },
      { text: 'Title', value: 'title' },
      { text: 'Total Amount', value: 'amount', align: 'right' },
      { text: 'Deducted', value: 'deducted', align: 'right' },
      { text: 'Balance', value: 'balance', align: 'right' },
      { text: 'Date granted', value: 'date', align: 'center' },
      { text: 'Start deducting from', value: 'start_deducting_from', align: 'center' },
      { text: 'Stop deducting from', value: 'stop_deducting_from', align: 'center' },
      { text: 'Payment Terms', value: 'payment_terms', align: 'right' },
      { text: 'Remaining terms', value: 'terms_remaining', align: 'right' },
      { text: 'Remarks', value: 'remarks' },
      { text: 'Action', value: 'actions' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const iconList = {
      add: mdiPlusOutline,
      edit: mdiPencilOutline,
      delete: mdiDeleteOutline,
    }

    const { remove, responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/payroll/deduction-entries/masterlist`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    const editItem = item => {
      emit('passData', item)
    }

    const deleteItem = async item => {
      await remove(`${process.env.VUE_APP_URI}/api/payroll/deduction-entries/${item.id}`, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    return {
      search,
      headers,
      items,
      searchTable,
      iconList,
      options,
      totalItems,
      loading,
      editItem,
      deleteItem,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },

  components: {
    snackbar,
  },
}
</script>
