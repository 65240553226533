<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        title="Add New"
        v-else
      >
        <v-icon>{{icon.add}}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="mt-4"
        text
        v-if="formState===null"
        @click="openUploader=true"
        title="Upload"
      >
        <v-icon>{{icon.upload}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <!-- employee -->
        <search-employee
          :employeeId="employee"
          :employeeNumber="employeeNumber"
          :disabled="formState!=='New'"
          :errorMessage="formErrorFields.employeeErrorMessage"
          @outputId="setEmployeeId"
        ></search-employee>

        <!-- deduction -->
        <v-autocomplete
          v-model="deduction"
          outlined
          dense
          clearable
          label="Deduction Title"
          item-text="title"
          item-value="id"
          :loading="deductionLoading"
          :items="deductions"
          :class="formErrorFields.deductionErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.deductionErrorMessage"
          :disabled="formState===null"
          @change="formError().remove('deduction', formErrorFields)"
        ></v-autocomplete>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Amount -->
            <v-text-field
              v-model="amount"
              label="Amount"
              outlined
              dense
              type="number"
              placeholder="Amount"
              :class="formErrorFields.amountErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.amountErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('amount', formErrorFields)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- type -->
            <v-text-field
              v-model="paymentTerms"
              label="Payment Terms"
              outlined
              dense
              type="number"
              placeholder="Payment Terms"
              :class="formErrorFields.paymentTermsErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.paymentTermsErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('paymentTerms', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Date Granted -->
        <v-dialog
          ref="dialog"
          v-model="modalDateGranted"
          :return-value.sync="dateGrantedValue"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateGranted"
              label="Date Granted"
              outlined
              dense
              placeholder="Date Granted"
              v-bind="attrs"
              v-on="on"
              :class="formErrorFields.dateGrantedErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.dateGrantedErrorMessage"
              :disabled="formState===null"
              @click="formError().remove('dateGranted', formErrorFields)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateGranted" scrollable>
            <v-btn text color="primary" @click="modalDateGranted=false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalDateGranted=false">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Start Deducting from -->
            <v-dialog
              ref="dialog"
              v-model="modalStartDeductingFrom"
              :return-value.sync="startDeductingFromValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDeductingFrom"
                  label="Start deducting"
                  outlined
                  dense
                  placeholder="Start deducting"
                  v-bind="attrs"
                  v-on="on"
                  :class="formErrorFields.startDeductingFromErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.startDeductingFromErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('startDeductingFrom', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDeductingFrom" scrollable>
                <v-btn text color="primary" @click="modalStartDeductingFrom=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalStartDeductingFrom=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Stop deducting from -->
            <v-dialog
              ref="dialog"
              v-model="modalStopDeductingFrom"
              :return-value.sync="stopDeductingFromValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="stopDeductingFrom"
                  label="Stop deducting"
                  outlined
                  dense
                  placeholder="Stop deducting"
                  v-bind="attrs"
                  v-on="on"
                  :class="formErrorFields.stopDeductingFromErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.stopDeductingFromErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('stopDeductingFrom', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="stopDeductingFrom" scrollable>
                <v-btn text color="primary" @click="modalStopDeductingFrom=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalStopDeductingFrom=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-textarea
          v-model="remarks"
          outlined
          dense
          clearable
          label="Remarks"
          class="mb-3"
          rows="3"
          :error-messages="formErrorFields.remarksErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('remarks', formErrorFields)"
        ></v-textarea>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

    <file-uploader
      :show="openUploader"
      @closeDialog="openUploader=false"
      :accept="'.xls,.xlsx'"
      :uri="uploadUri"
    ></file-uploader>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlusOutline, mdiUploadOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import FileUploader from '@/components/FileUploader.vue'
import SearchEmployee from '@/components/SearchEmployee.vue'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const openUploader = ref(false)
    const loading = ref(false)
    const message = ref('')
    const icon = {
      add: mdiPlusOutline,
      upload: mdiUploadOutline,
    }
    const employee = ref(null)
    const employeeNumber = ref('')
    const deductionLoading = ref(false)
    const deduction = ref('')
    const deductions = ref([])
    const amount = ref(null)
    const paymentTerms = ref(null)
    const dateGrantedValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const dateGranted = ref('')
    const startDeductingFromValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const startDeductingFrom = ref('')
    const stopDeductingFromValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const stopDeductingFrom = ref('')
    const remarks = ref('')
    const modalDateGranted = ref(false)
    const modalStartDeductingFrom = ref(false)
    const modalStopDeductingFrom = ref(false)
    const formState = ref(null)
    const formErrorFields = ref({
      employeeErrorMessage: '',
      deductionErrorMessage: '',
      amountErrorMessage: '',
      paymentTermsErrorMessage: '',
      dateGrantedErrorMessage: '',
      startDeductingFromErrorMessage: '',
      stopDeductingFromErrorMessage: '',
      remarksErrorMessage: '',
    })
    const uploadUri = ref(`${process.env.VUE_APP_URI}/api/payroll/deduction-entries/upload`)

    watch(() => props.data, () => {
      employee.value = props.data.employee_id
      employeeNumber.value = props.data.employee_number
      deduction.value = props.data.deduction_id
      amount.value = props.data.amount.replace(',', '')
      paymentTerms.value = props.data.payment_terms
      dateGranted.value = props.data.date
      startDeductingFrom.value = props.data.start_deducting_from
      stopDeductingFrom.value = props.data.stop_deducting_from
      remarks.value = props.data.remarks
      formState.value = 'Edit'
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      employee.value = null
      deduction.value = ''
      amount.value = null
      paymentTerms.value = null
      dateGranted.value = ''
      startDeductingFrom.value = ''
      stopDeductingFrom.value = ''
      remarks.value = ''
    }

    const submit = async () => {
      const formData = {
        employee: employee.value,
        deduction: deduction.value,
        amount: amount.value,
        paymentTerms: paymentTerms.value,
        dateGranted: dateGranted.value,
        startDeductingFrom: startDeductingFrom.value,
        stopDeductingFrom: stopDeductingFrom.value,
        remarks: remarks.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/deduction-entries`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/deduction-entries/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
        reset()
      }
    }

    const setEmployeeId = val => {
      employee.value = val
    }

    get(`${process.env.VUE_APP_URI}/api/payroll/deduction-entries/deductions`, deductions, deductionLoading)

    return {
      uploadUri,
      setEmployeeId,
      employee,
      employeeNumber,
      deductionLoading,
      deduction,
      deductions,
      amount,
      paymentTerms,
      startDeductingFrom,
      stopDeductingFrom,
      dateGranted,
      modalDateGranted,
      dateGrantedValue,
      modalStartDeductingFrom,
      startDeductingFromValue,
      modalStopDeductingFrom,
      stopDeductingFromValue,
      remarks,
      icon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      openUploader,
    }
  },

  components: {
    snackbar,
    FileUploader,
    SearchEmployee,
  },
}
</script>
